






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {round} from '@amcharts/amcharts4/.internal/core/utils/Math';

@Component({
      components: {},
      props: {
        addModeOn: Boolean,
        passedPatternData: []
      }
    }
)

export default class Patterns extends Vue {
  private placeholder = require('@/assets/pervaSafeLogo.png')
  private imagePresenter = this.placeholder
  private web_backend = 'https://hci.hochschule-trier.de:8443/files/'

  // origin page of "details", either "cards" or the name of the tag
  private webpage = ''

  // Picture upload
  private image = [] as any

  private deleteDropFile(index: number) {
    this.image.splice(index, 1)
  }

  // Loading screen
  private isFullPage = true

  //Pattern Filter & Search Bar
  private patterns = []

  // Pattern Data:
  private patternSelected = false

  private id = 0
  private name = ''
  private problem = ''
  private illustration = ''
  private context = ''
  private solution = ''
  private examples = ''
  private refs = ''
  private stage = 0
  // Relationships
  private noRelationships = false
  private usesPatterns = []
  private requiresPatterns = []
  private alternativePatterns = []
  private conflictsPatterns = []
  private contradictionPatterns = []


  // prev for resetting recent changes in Edit-Mode
  private prevName = ''
  private prevProblem = ''
  private prevIllustration = ''
  private prevContext = ''
  private prevSolution = ''
  private prevExamples = ''
  private prevRefs = ''
  private prevStage = 0
  private prevImage = [] as any
  private prevShowRatings = true
  private prevShowComments = true
  // Relationships
  private prevRequiresPatterns = []
  private prevUsesPatterns = []
  private prevAlternativePatterns = []
  private prevConflictsPatterns = []
  private prevContradictionPatterns = []
  // Feedback
  private accordingComments = []
  private newCommentTitle = ""
  private newCommentContent = ""
  private positivKeywordsUnderstandability = [] as any
  private negativKeywordsUnderstandability = [] as any
  private newPositivKeywordUnderstandability = ""
  private newNegativKeywordUnderstandability = ""
  private positivKeywordsRelevance = [] as any
  private negativKeywordsRelevance = [] as any
  private newPositivKeywordRelevance = ""
  private newNegativKeywordRelevance = ""
  private newRatingUnderstandability = 0
  private newRatingRelevance = 0
  private selectedKeywordsUnderstandability = []
  private selectedKeywordsRelevance = []
  private averageRatingUnderstandability = 0
  private averageRatingRelevance = 0
  private allRatingsUnderstandability = []
  private allRatingsRelevance = []
  private numberRatings = 0
  private feedbackLink = ""
  private showRatings = true
  private showComments = true
  // Tags
  private accordingTags = [] as any
  private newTag = ""

  //Different Modes
  private editMode = false
  private addMode = false
  private feedbackMode = false

  private async getPatterns() {
    Vue.axios.get(`${this.$store.state.root}/api/patterns`).then(resp => {
      this.patterns = resp.data
    })
  }

  private oneStepBack() {
    if (this.hasUnsavedChanges()) {
      this.$buefy.dialog.confirm({
        title: ('Back'),
        message: 'You\'ve made unsaved changes. Are you sure you want to <b>go back and discard Changes</b>? This action cannot be undone.',
        cancelText: 'Continue Editing',
        confirmText: 'Back and Discard',
        type: 'is-danger',
        hasIcon: true,
        // overwrite changes with prev data (before entering Edit-mode)
        onConfirm: () => {
          // Arg applyChanges = false to discard changes and overwrite with prevData
          if (this.editMode) {
            const pattern = this.generatePatternDataForDB(false, false)
            Vue.axios.put(`${this.$store.state.root}/api/patterns/${this.id}`, pattern)
                .then(async () => this.update(this.id))
            this.$store.commit('toggleComments', false)
          }
          this.$router.go(-1)
        }
      })
    } else {
      this.$router.go(-1)
    }
  }

  private checkPatternSelected() {
    if (!this.patternSelected && !this.addMode) {
      this.$router.go(-1)
    }
    return this.patternSelected
  }

  /*Feedback begin...*/
  private cancelComment() {
    this.$buefy.dialog.confirm({
      title: ('Reset Feedback'),
      message: 'Are you sure you want to <b>reset</b> your Feedback? This action cannot be undone.',
      confirmText: 'Reset',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.newCommentTitle = ""
        this.newCommentContent = ""
        this.newRatingUnderstandability = 0
        this.selectedKeywordsUnderstandability = []
        this.newRatingRelevance = 0
        this.selectedKeywordsRelevance = []
        this.$buefy.toast.open({
          message: `Feedback has been reset.`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    })
  }

  private toggleComments() {
    this.$store.commit('toggleComments', !this.$store.state.isOpen)
  }

  private postComment() {
    if (this.newCommentTitle != "" && this.newCommentContent != "" && this.newRatingUnderstandability != 0 && this.newRatingRelevance != 0) {
      const comment = {
        title: this.newCommentTitle,
        comment: this.newCommentContent,
        ratingUnderstandability: this.newRatingUnderstandability,
        selectedKeywordsUnderstandability: this.selectedKeywordsUnderstandability.join(),
        ratingRelevance: this.newRatingRelevance,
        selectedKeywordsRelevance: this.selectedKeywordsRelevance.join(),
        pattern: this.id
      }
      Vue.axios.post(`${this.$store.state.root}/api/comments`, comment)
          .then(async () => this.update(this.id))
      this.feedbackMode = false
      this.newCommentTitle = ""
      this.newCommentContent = ""
      this.newRatingUnderstandability = 0
      this.selectedKeywordsUnderstandability = []
      this.newRatingRelevance = 0
      this.selectedKeywordsRelevance = []

      // Confirmation on more feedback or back to start
      this.$buefy.dialog.confirm({
        title: 'Thank you for your Feedback!',
        message: 'We need more Feedback on other design patterns. Do you want to give more feedback?',
        cancelText: 'No more Feedback',
        confirmText: 'Give more Feedback',
        type: 'is-primary',
        onCancel: () => {
          this.$router.push('/')
        },
        onConfirm: () => {
          this.$store.commit('feedbackGiven', false)
          this.$router.push('feedback')
        },
        hasIcon: false,
        ariaRole: 'alertdialog',
        ariaModal: true
      })
      /**
       * this.$buefy.snackbar.open({
        message: 'Thank you for your feedback.',
        position: 'is-bottom',
        actionText: 'Give feedback on other patterns',
        onAction: () => this.$router.push('feedback'),
        type: 'is-black',
        duration: 6000,
        pauseOnHover: true
      })**/
    } else {
      this.$buefy.toast.open({
        message: 'Please leave rating, title and comment first.',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  private deleteComment(id: number) {
    this.$buefy.dialog.confirm({
      title: ('Deleting Comment'),
      message: 'Are you sure you want to <b>delete</b> this Comment? This action cannot be undone.',
      confirmText: 'Delete ',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        Vue.axios.delete(`${this.$store.state.root}/api/comments/${id}`)
            .then(async () => this.update(this.id))
        this.$buefy.toast.open({
          message: (`Comment was deleted`),
          position: 'is-top',
          type: 'is-danger'
        })
      }
    })
  }

  private addFeedbackKeyword(newKeyword: String, understandability: boolean, relevance: boolean, positiv: boolean, negativ: boolean) {
    if (this.hasUnsavedChanges()) {
      this.$buefy.dialog.alert({
        message: `You\'ve made unsaved changes. Please Save or Discard before adding a new Keyword!`,
        confirmText: 'Ok'
      })
    } else {
      if (newKeyword.trim() != "") {
        if (newKeyword.length <= 40) {
          const keyword = {
            keyword: newKeyword,
            understandability: understandability,
            relevance: relevance,
            positiv: positiv,
            negativ: negativ
          }
          Vue.axios.post(`${this.$store.state.root}/api/feedbackkeywords`, keyword)
              .then(async () => this.update(this.id))
          this.newPositivKeywordUnderstandability = ""
          this.newNegativKeywordUnderstandability = ""
          this.newPositivKeywordRelevance = ""
          this.newNegativKeywordRelevance = ""
        } else {
          this.$buefy.toast.open({
            message: `Keyword is too long. (Max. 40 characters)`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }

      } else {
        this.$buefy.toast.open({
          message: `Please enter a Keyword first`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
  }

  private deleteFeedbackKeyword(id: number) {
    if (this.hasUnsavedChanges()) {
      this.$buefy.dialog.alert({
        message: `You\'ve made unsaved changes. Please Save or Discard before adding a new Keyword!`,
        confirmText: 'Ok'
      })
    } else {
      this.$buefy.dialog.confirm({
        title: ('Deleting Keyword'),
        message: 'Are you sure you want to <b>delete</b> this Keyword for all patterns? This action cannot be undone.',
        confirmText: 'Delete ',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          Vue.axios.delete(`${this.$store.state.root}/api/feedbackkeywords/${id}`)
              .then(async () => this.update(this.id))
          this.$buefy.toast.open({
            message: (`Keyword was deleted`),
            position: 'is-top',
            type: 'is-danger'
          })
        }
      })
    }
  }

  private async copyFeedbackLink() {
    try {
      await navigator.clipboard.writeText(this.feedbackLink);
      this.$buefy.toast.open({
        message: `Copied`,
        position: 'is-bottom',
        type: 'is-primary'
      })
    } catch ($err) {
      this.$buefy.toast.open({
        message: (`Cannot copy`),
        position: 'is-top',
        type: 'is-danger'
      })
    }
  }

  /*...Feedback end */

  /* Methods for Edit-mode begin ...*/
  private enterEditMode() {
    this.editMode = true
    this.addMode = false
    this.$store.commit('toggleComments', true)
  }

  // returns true if values changed since entering Edit-Mode
  private hasUnsavedChanges() {
    if (this.editMode || this.addMode) {
      return this.prevName != this.name ||
          this.prevProblem != this.problem || this.prevIllustration != this.illustration ||
          this.prevContext != this.context ||
          this.prevSolution != this.solution || this.prevExamples != this.examples ||
          this.prevRefs != this.refs || this.prevStage != this.stage ||
          this.prevShowRatings != this.showRatings || this.prevShowComments != this.showComments;
    }
  }

  // update Pattern in database when saved
  private async updatePattern() {
    const loading = this.$buefy.loading.open({
      container: this.isFullPage ? null : this.$refs.addPattern
    })
    // check if changes were made else " was not changed"
    if (!this.hasUnsavedChanges() && this.image[0] == this.prevImage[0]) {
      this.$buefy.toast.open({
        message: (this.name.toString() + " was not changed"),
        position: 'is-top',
        type: 'is-success'
      })
      loading.close()
      this.editMode = false
      this.$store.commit('toggleComments', false)
    } else if (this.name.toString() != "" && this.checkName(this.name.toString()) || this.prevName == this.name) {
      // check if changes on content were made (image == prevImage)
      if (this.hasUnsavedChanges() && this.image[0] == this.prevImage[0]) {
        // Image did not change, ergo upload new content without image
        const pattern = this.generatePatternDataForDB(true, false)
        //Upload Content
        Vue.axios.put(`${this.$store.state.root}/api/patterns/${this.id}`, pattern)
            .then(async () => {
              await this.getPatternId(this.name)
                  .then(async () => {
                    await this.update(this.id)
                  })
              loading.close()
            })

        this.$buefy.toast.open({
          message: (this.name.toString() + " has been updated"),
          position: 'is-top',
          type: 'is-success'
        })
        this.editMode = false
        this.$store.commit('toggleComments', false)

      } else if (this.hasUnsavedChanges() && this.image[0] != this.prevImage[0]) {
        // Image changed or deleted?
        if (this.image[0] != null) {
          // image to big?
          if (this.image[0].size < 5120000) {
            let formData = new FormData()
            let img = this.image[0]
            formData.append('file', img)
            const pattern = this.generatePatternDataForDB(true, true)
            //upload Image
            Vue.axios.post(`${this.$store.state.root}/files`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(() => {
              //Upload Content
              Vue.axios.put(`${this.$store.state.root}/api/patterns/${this.id}`, pattern)
                  .then(async () => {
                    await this.getPatternId(this.name)
                        .then(async () => {
                          await this.update(this.id)
                        })
                    loading.close()
                  })

              this.$buefy.toast.open({
                message: (this.name.toString() + " has been updated"),
                position: 'is-top',
                type: 'is-success'
              })
              this.editMode = false
              this.$store.commit('toggleComments', false)
            })
          } else {
            loading.close()
            this.$buefy.toast.open({
              message: ("Image ist too big! Maximum 5MB."),
              position: 'is-top',
              type: 'is-danger'
            })
          }

        } else {
          //deleted Image , no new
          this.prevImage = null  // ?
          const pattern = this.generatePatternDataForDB(true, false)
          //Upload Content
          Vue.axios.put(`${this.$store.state.root}/api/patterns/${this.id}`, pattern)
              .then(async () => {
                await this.getPatternId(this.name)
                    .then(async () => {
                      await this.update(this.id)
                    })
                loading.close()
              })

          this.$buefy.toast.open({
            message: (this.name.toString() + " has been updated"),
            position: 'is-top',
            type: 'is-success'
          })
          this.editMode = false
          this.$store.commit('toggleComments', false)

        }
      } else if (!this.hasUnsavedChanges() && this.image[0] != this.prevImage[0]) {
        if (this.image[0] === undefined) {
          this.image[0] = ""
        } else if (this.image[0].size < 5120000) {
          const pattern = this.generatePatternDataForDB(true, true)
          let formData = new FormData()
          let img = this.image[0]
          formData.append('file', img)
          //upload Image
          Vue.axios.post(`${this.$store.state.root}/files`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => {
            loading.close()
            //Upload Content
            Vue.axios.put(`${this.$store.state.root}/api/patterns/${this.id}`, pattern)
                .then(async () => {
                  await this.getPatternId(this.name)
                      .then(async () => {
                        await this.update(this.id)
                      })
                  loading.close()
                })
            this.$buefy.toast.open({
              message: (this.name.toString() + " has been updated"),
              position: 'is-top',
              type: 'is-success'
            })
            this.editMode = false
            this.$store.commit('toggleComments', false)
          })
        } else {
          loading.close()
          this.$buefy.toast.open({
            message: ("Image ist too big! Maximum 5MB."),
            position: 'is-top',
            type: 'is-danger'
          })
        }
      } else {
        this.$buefy.toast.open({
          message: ("Something went wrong!"),
          position: 'is-top',
          type: 'is-warning'
        })
        loading.close()
      }
    } else {
      if (this.name.toString() == "") {
        this.$buefy.toast.open({
          message: ("Please give your Pattern a name!"),
          position: 'is-top',
          type: 'is-danger'
        })
        loading.close()
      } else {
        this.$buefy.toast.open({
          message: ("Patternname already exists!"),
          position: 'is-top',
          type: 'is-danger'
        })
        loading.close()
      }
    }
  }

  // discard changes if cancel in Edit-mode
  private cancel() {
    // returns true if changes were made...
    if (this.hasUnsavedChanges()) {
      this.$buefy.dialog.confirm({
        title: ('Canceling'),
        message: 'You\'ve made unsaved changes. Are you sure you want to <b>cancel and discard Changes</b>? This action cannot be undone.',
        cancelText: 'Continue Editing',
        confirmText: 'Cancel and Discard',
        type: 'is-danger',
        hasIcon: true,
        // overwrite changes with prev data (before entering Edit-mode)
        onConfirm: () => {
          // Arg applyChanges = false to discard changes and overwrite with prevData
          const pattern = this.generatePatternDataForDB(false, false)
          Vue.axios.put(`${this.$store.state.root}/api/patterns/${this.id}`, pattern)
              .then(async () => this.update(this.id))
          this.editMode = false
          this.addMode = false
          this.$store.commit('toggleComments', false)
          this.$buefy.toast.open({
            message: `Canceled`,
            position: 'is-top',
            type: 'is-danger'
          })
        }
      })
    }
    // no: leave Edit- or Add-mode
    else {
      this.editMode = false
      this.addMode = false
      this.$store.commit('toggleComments', false)
      this.$buefy.toast.open({
        message: `Canceled`,
        position: 'is-top',
        type: 'is-danger'
      })
    }
  }

  // delete selected Pattern from database
  private deletePattern() {
    this.$buefy.dialog.confirm({
      title: ('Deleting ' + this.name.toString()),
      message: 'Are you sure you want to <b>delete ' + this.name.toString()
          + '</b>? This action cannot be undone.',
      confirmText: 'Delete ',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        Vue.axios.delete(`${this.$store.state.root}/api/patterns/${this.id}`)
            .then(async () => {
              await this.getData()
            })
        this.$store.commit('toggleComments', false);

        this.$buefy.toast.open({
          message: (this.name.toString() + ` was deleted`),
          position: 'is-top',
          type: 'is-danger'
        })
        this.$router.go(-1)
      }
    })
  }

  private rejectPattern() {
    this.$buefy.dialog.confirm({
      title: ('Rejecting ' + this.name.toString()),
      message: 'Are you sure you want to <b>reject ' + this.name.toString() + '</b>?',
      confirmText: 'Reject',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        const pattern = this.generatePatternDataForDB(false, false)
        pattern.stage = 4
        Vue.axios.put(`${this.$store.state.root}/api/patterns/${this.id}`, pattern)
        this.$store.commit('toggleComments', false);

        this.$buefy.toast.open({
          message: (this.name.toString() + ` was rejected`),
          position: 'is-top',
          type: 'is-danger'
        })
        this.$router.go(-1)
      }
    })
  }

  private recoverPattern() {
    this.$buefy.dialog.confirm({
      title: ('Recover ' + this.name.toString()),
      message: '<b>Recovering ' + this.name.toString()
          + '</b> will set the stage back to "Created" and enables the editing',
      confirmText: 'Recover',
      type: 'is-success',
      hasIcon: true,
      onConfirm: () => {
        const pattern = this.generatePatternDataForDB(false, false)
        pattern.stage = 0
        Vue.axios.put(`${this.$store.state.root}/api/patterns/${this.id}`, pattern)
            .then(async () => {
              await this.update(this.id)
            })
        this.$store.commit('toggleComments', false);

        this.$buefy.toast.open({
          message: (this.name.toString() + ` was recovered`),
          position: 'is-top',
          type: 'is-success'
        })
      }
    })
  }

  /*Tagging begin...*/
  private addTag() {
    if (this.hasUnsavedChanges()) {
      this.$buefy.dialog.alert({
        message: `You\'ve made unsaved changes. Please Save or Discard before adding a new Tag!`,
        confirmText: 'Ok'
      })
    } else {
      if (this.newTag.trim() != "") {
        if (this.newTag.length <= 30) {
          const tag = {
            tag: this.newTag,
            pattern: this.id
          }
          Vue.axios.post(`${this.$store.state.root}/api/tags`, tag)
              .then(async () => this.update(this.id))
          this.newTag = ""
        } else {
          this.$buefy.toast.open({
            message: `Tag is too long. (Max. 30 characters)`,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }

      } else {
        this.$buefy.toast.open({
          message: `Please enter a Tag first`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
  }

  private deleteTag(id: number) {
    if (this.hasUnsavedChanges()) {
      this.$buefy.dialog.alert({
        message: `You\'ve made unsaved changes. Please Save or Discard before deleting a Tag!`,
        confirmText: 'Ok'
      })
    } else {
      this.$buefy.dialog.confirm({
        title: ('Deleting Tag'),
        message: 'Are you sure you want to <b>delete</b> this Tag? This action cannot be undone.',
        confirmText: 'Delete ',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          Vue.axios.delete(`${this.$store.state.root}/api/tags/${id}`)
              .then(async () => this.update(this.id))
          this.$buefy.toast.open({
            message: (`Tag was deleted`),
            position: 'is-top',
            type: 'is-danger'
          })
        }
      })
    }
  }

  /*... Tagging end*/
  /*... Edit-mode end*/

  /* Update methods for getting all Patterns from the database and showing the selected Pattern begin...*/
  private async getPatternId(name: String) {

    Vue.axios.get(`${this.$store.state.root}/api/patterns`).then(async resp => {
      this.patternSelected = true
      let patternData = await resp.data
      let matchingPatterns = patternData.filter((pattern: any) => (pattern.name == name)).map((x: any) => x.patternId)
      let patternId = matchingPatterns[0]

      await this.update(patternId)
      this.id = patternId
      this.addMode = false
    })
  }

  private async setPatternData(allResponses: Response[]) {
    const patternData = await allResponses[0].json()
    this.stage = parseInt(patternData.stage)
    this.name = patternData.name
    this.problem = patternData.problem
    this.illustration = patternData.illustration
    this.context = patternData.context
    this.solution = patternData.solution
    this.examples = patternData.examples
    this.refs = patternData.refs
    if (patternData.requires != null && patternData.requires != [""]) {
      this.requiresPatterns = patternData.requires.split(', ')
    } else {
      this.requiresPatterns = []
    }
    if (patternData.uses != null && patternData.uses != [""]) {
      this.usesPatterns = patternData.uses.split(', ')
    } else {
      this.usesPatterns = []
    }
    if (patternData.alternative != null && patternData.alternative != [""]) {
      this.alternativePatterns = patternData.alternative.split(', ')
    } else {
      this.alternativePatterns = []
    }
    if (patternData.conflicts != null && patternData.conflicts != [""]) {
      this.conflictsPatterns = patternData.conflicts.split(', ')
    } else {
      this.conflictsPatterns = []
    }
    if (patternData.contradiction != null && patternData.contradiction != [""]) {
      this.contradictionPatterns = patternData.contradiction.split(', ')
    } else {
      this.contradictionPatterns = []
    }
    if (patternData.image != null) {
      try {
        this.imagePresenter = this.web_backend + patternData.image
        let tmp = patternData.image
        this.image[0] = tmp
        this.prevImage.push(patternData.image)
      } catch (err) {
        this.imagePresenter = this.placeholder
      }
    } else {
      this.imagePresenter = this.placeholder
    }
    if (this.usesPatterns[0] == null && this.requiresPatterns[0] == null &&
        this.alternativePatterns[0] == null &&
        this.conflictsPatterns[0] == null && this.contradictionPatterns[0] == null) {
      this.noRelationships = true
    } else {
      this.noRelationships = false
    }
    this.showRatings = patternData.showRatings
    this.showComments = patternData.showComments

    const commentData = await allResponses[1].json()
    this.accordingComments = commentData.filter((c: any) => (c.pattern == this.id)).reverse()
    this.allRatingsUnderstandability = this.accordingComments.map((int: any) => int.ratingUnderstandability) as []
    const sumUnderstandability = this.allRatingsUnderstandability.reduce((a: any, b: any) => a + b, 0)
    this.averageRatingUnderstandability = round((sumUnderstandability / this.allRatingsUnderstandability.length), 1)
    if (Number.isNaN(this.averageRatingUnderstandability)) {
      this.averageRatingUnderstandability = 0
    }
    this.allRatingsRelevance = this.accordingComments.map((int: any) => int.ratingRelevance) as []
    const sumRelevance = this.allRatingsRelevance.reduce((a: any, b: any) => a + b, 0)
    this.averageRatingRelevance = round((sumRelevance / this.allRatingsRelevance.length), 1)
    if (Number.isNaN(this.averageRatingRelevance)) {
      this.averageRatingRelevance = 0
    }
    this.numberRatings = this.allRatingsUnderstandability.length


    const tagData = await allResponses[2].json()
    this.accordingTags = tagData.filter((t: any) => (t.pattern == this.id))

    const feedbackKeywordData = await allResponses[3].json()
    this.positivKeywordsUnderstandability = feedbackKeywordData.filter((k: any) => (k.understandability && k.positiv))
    this.negativKeywordsUnderstandability = feedbackKeywordData.filter((k: any) => (k.understandability && k.negativ))
    this.positivKeywordsRelevance = feedbackKeywordData.filter((k: any) => (k.relevance && k.positiv))
    this.negativKeywordsRelevance = feedbackKeywordData.filter((k: any) => (k.relevance && k.negativ))

    // prev Pattern data (copy for discard changes)
    this.prevStage = parseInt(patternData.stage)
    this.prevName = patternData.name
    this.prevProblem = patternData.problem
    this.prevIllustration = patternData.illustration
    this.prevContext = patternData.context
    this.prevSolution = patternData.solution
    this.prevExamples = patternData.examples
    this.prevRefs = patternData.refs
    if (patternData.requires != null) {
      this.prevRequiresPatterns = patternData.requires.split(', ')
    } else {
      this.prevRequiresPatterns = []
    }
    if (patternData.uses != null) {
      this.prevUsesPatterns = patternData.uses.split(', ')
    } else {
      this.prevUsesPatterns = []
    }
    if (patternData.alternative != null) {
      this.prevAlternativePatterns = patternData.alternative.split(', ')
    } else {
      this.prevAlternativePatterns = []
    }
    if (patternData.conflicts != null) {
      this.prevConflictsPatterns = patternData.conflicts.split(', ')
    } else {
      this.prevConflictsPatterns = []
    }
    if (patternData.contradiction != null) {
      this.prevContradictionPatterns = patternData.contradiction.split(', ')
    } else {
      this.prevContradictionPatterns = []
    }
    this.prevShowRatings = patternData.showRatings
    this.prevShowComments = patternData.showComments
  }

  private async getData() {
    Promise.all([
      fetch(`${this.$store.state.root}/api/patterns/`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/comments/`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/tags/`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/feedbackkeywords`, {
        method: 'GET'
      })
    ]).then(async allResponses => {
      await this.setPatternData(allResponses)
    })
  }

  // update Pattern data from database and show Pattern with id x (x = selected through mouse click)
  private async update(x: Number) {
    Promise.all([
      fetch(`${this.$store.state.root}/api/patterns/${x}`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/comments/`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/tags`, {
        method: 'GET'
      }),
      fetch(`${this.$store.state.root}/api/feedbackkeywords`, {
        method: 'GET'
      })
    ]).then(async allResponses => {
      await this.setPatternData(allResponses)
    })
  }

  // preparing the Pattern data for updating Database. Set applyChanges = false for discarding changes
  private generatePatternDataForDB(applyChanges: boolean, newImage: boolean) {
    if (applyChanges) {
      // check if new Image was selected
      if (!newImage) {
        const pattern = {
          name: this.name,
          problem: this.problem,
          illustration: this.illustration,
          context: this.context,
          solution: this.solution,
          examples: this.examples,
          refs: this.refs,
          stage: this.stage,
          requires: this.requiresPatterns.join(', '),
          uses: this.usesPatterns.join(', '),
          alternative: this.alternativePatterns.join(', '),
          conflicts: this.conflictsPatterns.join(', '),
          contradiction: this.contradictionPatterns.join(', '),
          image: this.prevImage[0],
          showRatings: this.showRatings,
          showComments: this.showComments
        }
        return pattern
      } else {
        const pattern = {
          name: this.name,
          problem: this.problem,
          illustration: this.illustration,
          context: this.context,
          solution: this.solution,
          examples: this.examples,
          refs: this.refs,
          stage: this.stage,
          requires: this.requiresPatterns.join(', '),
          uses: this.usesPatterns.join(', '),
          alternative: this.alternativePatterns.join(', '),
          conflicts: this.conflictsPatterns.join(', '),
          contradiction: this.contradictionPatterns.join(', '),
          image: this.image[0].name,
          showRatings: this.showRatings,
          showComments: this.showComments
        }
        return pattern
      }
    } else {
      const prevPattern = {
        name: this.prevName,
        problem: this.prevProblem,
        illustration: this.prevIllustration,
        context: this.context,
        solution: this.prevSolution,
        examples: this.prevExamples,
        refs: this.prevRefs,
        stage: this.prevStage,
        requires: this.prevRequiresPatterns.join(', '),
        uses: this.prevUsesPatterns.join(', '),
        alternative: this.prevAlternativePatterns.join(', '),
        conflicts: this.prevConflictsPatterns.join(', '),
        contradiction: this.prevContradictionPatterns.join(', '),
        image: this.prevImage[0],
        showRatings: this.prevShowRatings,
        showComments: this.prevShowComments
      }
      return prevPattern
    }
  }

  /*... Update end*/

  /*Add Pattern begin... */
  private enterAddMode() {
    this.name = ""
    this.problem = ""
    this.illustration = ""
    this.context = ""
    this.solution = ""
    this.examples = ""
    this.refs = ""
    this.stage = 0
    this.requiresPatterns = []
    this.usesPatterns = []
    this.alternativePatterns = []
    this.conflictsPatterns = []
    this.contradictionPatterns = []
    this.accordingTags = []
    this.patternSelected = false
    this.showRatings = true
    this.showComments = true

    // copy for changes
    this.prevName = ""
    this.prevProblem = ""
    this.prevIllustration = ""
    this.prevContext = ""
    this.prevSolution = ""
    this.prevExamples = ""
    this.prevRefs = ""
    this.prevStage = 0
    this.prevRequiresPatterns = []
    this.prevUsesPatterns = []
    this.prevAlternativePatterns = []
    this.prevConflictsPatterns = []
    this.prevContradictionPatterns = []
    this.prevShowRatings = true
    this.prevShowComments = true

    this.addMode = true
    this.editMode = false
  }

  private checkName(name: string) {
    let bool = true
    this.patterns.forEach((pattern: any) => {
      if (pattern.name == name) {
        bool = false
      }
    })
    return bool
  }

  private async addPattern() {
    const loading = this.$buefy.loading.open({
      container: this.isFullPage ? null : this.$refs.addPattern
    })
    if (this.name.toString() != "" && this.checkName(this.name.toString())) {
      const pattern = this.generatePatternDataForDB(true, true)
      if (this.image[0] != null) {
        if (this.image[0].size < 5125000) {
          let formData = new FormData()
          formData.append('file', this.image[0])
          //upload Image
          await Vue.axios.post(`${this.$store.state.root}/files`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => {
                // Upload content
                Vue.axios.post(`${this.$store.state.root}/api/patterns`, pattern)
                    .then(async () => {
                      loading.close()
                      await this.getPatternId(this.name)
                          .then(async () => {
                            await this.update(this.id)
                          })
                    })
                this.$buefy.toast.open({
                  message: (this.name.toString() + " has been added"),
                  position: 'is-top',
                  type: 'is-success'
                })
              }
          )
        }
        if (this.image[0].size > 5125000) {
          loading.close()
          this.$buefy.toast.open({
            message: ("Image is too big! Maximum 5MB."),
            position: 'is-top',
            type: 'is-danger'
          })
        }
      } else {
        // Upload content
        Vue.axios.post(`${this.$store.state.root}/api/patterns`, pattern)
            .then(async () => {
              loading.close()
              await this.getPatternId(this.name)
                  .then(async () => {
                    await this.update(this.id)
                  })
            })
        this.$buefy.toast.open({
          message: (this.name.toString() + " has been added"),
          position: 'is-top',
          type: 'is-success'
        })
      }
    } else {
      if (!this.checkName(this.name)) {
        loading.close()
        this.$buefy.toast.open({
          message: `Pattern Name already exists!`,
          position: 'is-top',
          type: 'is-danger'
        })
      } else {
        loading.close()
        this.$buefy.toast.open({
          message: `Please enter a name first`,
          position: 'is-top',
          type: 'is-danger'
        })
      }
    }
  }

  /*... Add Pattern end*/

  private shortenDetailText(text: String) {
    if (text == null || text == '' || text.length < 150) {
      return text
    }
    return text.slice(0, 150) + " ..."
  }

  private getStageName(stage: number) {
    switch (stage) {
      case 0:
        return 'Created'
      case 1:
        return 'Consideration'
      case 2:
        return 'Candidate'
      case 3:
        return 'Approved'
      case 4:
        return 'Rejected'
      default:
        return '-'
    }
  }

  created() {
    // this.getData()
    if (this.$props.passedPatternData.patternName) {
      this.webpage = this.$props.passedPatternData.webpage
      this.patternSelected = true
      this.name = this.$props.passedPatternData.patternName
      this.getPatternId(this.name)
      //TODO: Change URL
      this.feedbackLink = 'localhost:8081/#/feedback?pattern=' + this.name.replaceAll(' ', '%20')
    }
    if (this.$props.addModeOn) {
      this.addMode = true
      this.webpage = "Cards"
      this.enterAddMode()
    }
    if (this.$route.params.feedbackMode === "true") {
      this.feedbackMode = true
      this.webpage = "Feedback"
      if(this.$route.params.displayMessage === "true") {
        console.log()
        this.$buefy.dialog.alert({
          title: 'Feedback',
          message: 'We need your Feedback. Please read the pattern and give your feedback at the end of the pattern.',
          type: 'is-primary',
          hasIcon: false,
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    }
    this.getPatterns()
  }
}
